import { Client, GithubToken, MetricsVisability, Status } from "../models";
import React, { useEffect, useState } from "react";
import { DataStore } from "aws-amplify";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  ModalProps,
} from "@mui/material";
import { Button, TextField } from "@aws-amplify/ui-react";

interface ClientCreateProps {
  open: boolean;
  onClose?: ModalProps["onClose"];
}
interface ClientForToken {
  name: string;
  url: string;
  githubToken: string;
  isScaleOpsDev: boolean;
  isFreeTrial: boolean;
  metricsVerbosity: MetricsVisability;
}
export default function ClientCreate(props: ClientCreateProps) {
  const [clientName, setClientName] = useState<string>("");
  const [isFreeTrial] = useState<boolean>(false);
  const [encKey, setEncKey] = useState<CryptoKey | null>(null);

  useEffect(() => {
    crypto.subtle
      .generateKey(
        {
          name: "HMAC",
          hash: { name: "SHA-256" },
        },
        true,
        ["sign", "verify"],
      )
      .then((key) => {
        setEncKey(key);
        console.log("encryption key generated");
      });
  }, []);

  const makeid = (length: number): string => {
    let result = "";
    const characters = "abcdefghijklmnopqrstuvwxyz0123456789";
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  };

  const genUrl = (): string => {
    return clientName.toLowerCase().split(" ").join("-") + "/" + makeid(5);
  };

  const buf2hex = (buffer: ArrayBuffer): string => {
    return Array.from(new Uint8Array(buffer))
      .map((x) => x.toString(16).padStart(2, "0"))
      .join("")
      .slice(0, 33);
  };

  const genScaleOpsToken = async (client: ClientForToken): Promise<string> => {
    if (!encKey) {
      throw new Error("encryption key not set");
    }
    const enc = new TextEncoder();
    const now = new Date();
    const encoded = enc.encode(client.name + now.toISOString() + client.url);
    try {
      const signature = await crypto.subtle.sign("HMAC", encKey, encoded);
      return "soc_" + buf2hex(signature);
    } catch (reason) {
      console.error(reason);
      throw reason;
    }
  };

  const save = () => {
    const clientNameTrimmed = clientName.trim();
    if (clientNameTrimmed.length === 0) {
      return;
    }
    const url = genUrl();
    const client: ClientForToken = {
      name: clientNameTrimmed,
      url: url,
      metricsVerbosity: MetricsVisability.KEEP,
      isScaleOpsDev: false,
      isFreeTrial: isFreeTrial,
      githubToken: "fake",
    };
    if (isFreeTrial) {
      genScaleOpsToken(client).then((scaleOpsToken) => {
        DataStore.save(
          new Client({
            ...client,
            useScaleOpsToken: true,
            scaleOpsToken: scaleOpsToken,
            status: Status.FREE_TRIAL,
          }),
        )
          .then((saveResult) => {
            console.log("client created successfully ", saveResult);
            if (props.onClose) {
              props.onClose({}, "escapeKeyDown");
            }
          })
          .catch((reason) => {
            console.error(reason);
          });
      });
    } else {
      // Once we will move away from Github Tokens all together, we will need to remove this query
      DataStore.query(
        GithubToken,
        (g) => g.and((gp) => [gp.inUse.eq(false), gp.deleted.eq(false)]),
        {
          page: 0,
          limit: 1,
        },
      ).then((tokenResult) => {
        if (tokenResult.length !== 1) {
          throw new Error("failed to locate an available github token");
        }
        const githubToken = tokenResult[0];
        client.githubToken = githubToken.token;

        genScaleOpsToken(client)
          .then((scaleOpsToken) => {
            DataStore.save(
              new Client({
                ...client,
                useScaleOpsToken: false,
                scaleOpsToken: scaleOpsToken,
                status: Status.PREMIUM,
              }),
            )
              .then((saveResult) => {
                console.log("client created successfully ", saveResult);
                DataStore.save(
                  GithubToken.copyOf(githubToken, (draft) => {
                    draft.inUse = true;
                  }),
                )
                  .then((saveTokenResult) => {
                    console.log("token updated successfully ", saveTokenResult);
                    if (props.onClose) {
                      props.onClose({}, "escapeKeyDown");
                    }
                  })
                  .catch((reason) => {
                    console.error(reason);
                  });
              })
              .catch((reason) => {
                console.error(reason);
              });
          })
          .catch((reason) => {
            console.error(reason);
          });
      });
    }
  };

  // @ts-ignore
  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <DialogTitle>Create a new client</DialogTitle>
      <DialogContent>
        {/*
          // @ts-ignore */}
        <TextField
          isRequired={true}
          label="Client Name"
          onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
            setClientName(event.target.value);
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => save()}>Save</Button>
      </DialogActions>
    </Dialog>
  );
}
