import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Typography } from "@mui/material";
import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import useIsTextIsSelectedInAnElement from "../hooks/useIsTextIsSelectedInAnElement";
import HideIcon from "../icons/HideIcon";
import ShowIcon from "../icons/ShowIcon";
import Tooltip from "./Tooltip";

const DISABLE_COPY_ON_CLICKING_ON_DIV = true;

export enum THEME {
  dark = "dark",
  light = "light",
}
interface Props {
  title?: string;
  description?: React.ReactNode;
  info?: React.ReactNode;
  codeSnippet: string;
  codeSnippetToCopy?: string;
  className?: string;
  theme?: THEME;
}

const CodeSnippet = ({
  title,
  description,
  info,
  codeSnippet,
  codeSnippetToCopy,
  className,
  theme = THEME.dark,
}: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const isTextIsSelectedInAnElement = useIsTextIsSelectedInAnElement(ref);

  const [showCopied, setShowCopied] = useState<boolean>(false);
  const [showToken, setShowToken] = useState<boolean>(false);

  const iconClassName = clsx({
    "hover:text-primary-lightPurpleBlue cursor-pointer": theme === THEME.dark,
    "text-guideline-darkPurple hover:text-guideline-darkBlue cursor-pointer":
      theme === THEME.light,
  });

  const codeToDisplay =
    showToken && codeSnippetToCopy ? codeSnippetToCopy : codeSnippet;

  useEffect(() => {
    if (showCopied) {
      const timeout = setTimeout(() => {
        setShowCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [showCopied]);

  useEffect(() => {
    if (isTextIsSelectedInAnElement) setShowToken(true);
  }, [isTextIsSelectedInAnElement]);

  const onClick = () => {
    setShowCopied(true);
    let codeToCopy = codeSnippetToCopy ?? codeSnippet;
    codeToCopy = codeToCopy.trim();
    navigator.clipboard.writeText(codeToCopy);
  };

  return (
    <div className={clsx(className, "flex flex-col gap-4 select-none")}>
      {title && (
        <Typography
          variant="body1"
          fontWeight={700}
          className="flex gap-2 item-center"
        >
          {title}
          {info && (
            <Tooltip title={info}>
              <InfoOutlinedIcon style={{ width: 14 }} />
            </Tooltip>
          )}
        </Typography>
      )}
      {description && <Typography variant="body2">{description}</Typography>}
      <div
        className={clsx(
          "rounded-md text-sm font-mono overflow-x-auto hide-scroll-bar relative",
          {
            "bg-black text-white": theme === THEME.dark,
            "bg-background-chip text-black": theme === THEME.light,
          },
        )}
      >
        <div className="absolute top-2 right-2 flex justify-end items-center gap-2 h-[20px]">
          {codeSnippetToCopy && (
            <Tooltip title="Show/Hide">
              <div
                onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                  e.stopPropagation();
                  setShowToken(!showToken);
                }}
              >
                {showToken ? (
                  <ShowIcon className={iconClassName} />
                ) : (
                  <HideIcon className={iconClassName} />
                )}
              </div>
            </Tooltip>
          )}
          <Tooltip title="Copy code to clipboard">
            <div
              onClick={onClick}
              className={clsx(
                "border rounded-lg px-2 text-[10px] h-[16px] flex items-center justify-center cursor-pointer",
                {
                  "hover:text-primary-lightPurpleBlue": theme === THEME.dark,
                  "text-guideline-darkPurple hover:text-guideline-darkBlue border-guideline-darkPurple hover:border-guideline-darkBlue bg-[#dadafb] hover:bg-[#A3A2F9]":
                    theme === THEME.light,
                },
              )}
            >
              copy
            </div>
          </Tooltip>
        </div>
        <div
          onClick={() => {
            if (DISABLE_COPY_ON_CLICKING_ON_DIV) return;
            onClick();
          }}
          className="p-4 max-[1298px]:py-8 whitespace-pre-wrap select-text"
          ref={ref}
        >
          {codeToDisplay.trim()}
        </div>
        <div
          className={clsx(
            "bg-[rgba(255,255,255,0.8)] absolute bottom-[2px] right-[2px] text-black rounded-md p-2 fade-in-and-out",
            {
              hidden: !showCopied,
            },
          )}
        >
          <Typography variant="body2">Copied to clipboard</Typography>
        </div>
      </div>
    </div>
  );
};

export default CodeSnippet;
